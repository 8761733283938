<template>
  <div>
    <div class="pc container">
      <div class="h5 main" style="padding: 40px 0px 28px;">내 쿠폰</div>
      <div v-if="list && list.length === 0" class="empty-view">보유하신 쿠폰이 없습니다.</div>
      <div v-else-if="list && list.length>0" class="grid-card">
        <div v-for="(card,idx) in list" :key="`card-${idx}`"
          class="card">
          <div class="tag" v-if="card.used">이용완료</div>
          <div class="tag" v-else-if="card.expired">사용기간 만료</div>
          <div>
            <div class="subtitle4" :class="card.used || card.expired ? 'sub4' : 'main'">{{ card.coupon.name }}</div>
            <div class="body3 margin-top-8" :class="card.used || card.expired ? 'sub4' : 'sub2'">{{ card.coupon.description }}</div>
            <div class="body4 margin-top-28" :class="card.used || card.expired ? 'sub4' : 'sub3'">사용기간 {{ datesFormat(card.start_date, 'date_3') }} ~ {{ datesFormat(card.expired_date, 'date_3') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile container">
      <div class="h5 main" style="padding: 40px 0px 28px;">내 쿠폰</div>
      <div v-if="list && list.length === 0" class="empty-view">보유하신 쿠폰이 없습니다.</div>
      <div v-else-if="list && list.length>0" class="grid-card">
        <div v-for="(card,idx) in list" :key="`card-${idx}`"
             class="card">
          <div class="tag" v-if="card.used">이용완료</div>
          <div class="tag" v-else-if="card.expired">사용기간 만료</div>
          <div>
            <div class="subtitle5" :class="card.used || card.expired ? 'sub4' : 'main'">{{ card.coupon.name }}</div>
            <div class="body5 margin-top-8" :class="card.used || card.expired ? 'sub4' : 'sub2'">{{ card.coupon.description }}</div>
            <div class="body6 margin-top-16" :class="card.used || card.expired ? 'sub4' : 'sub3'">사용기간 {{ datesFormat(card.start_date, 'date_3') }} ~ {{ datesFormat(card.expired_date, 'date_3') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "MyCoupon",
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getData();
    },
    data() {
      return {
        list: undefined
      }
    },
    methods: {
      getData() {
        this.request.user.get('coupon').then(res => {
          this.list = res.data;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .grid-card
    display grid

  .card
    border 1px solid $gray
    border-radius 8px
    background-color white
    height 200px
    display flex
    align-items center
    text-align center
    justify-content center
    position relative
    .tag
      background-color $sub3
      color white
      padding 2px 4px
      border-radius 4px
      font-size 12px
      position absolute
      top 20px
      left 20px

  .pc
    .grid-card
      grid-gap 24px
      grid-template-columns repeat(2, 1fr)

  .mobile
    .grid-card
      grid-gap 12px
    .card
      height 160px

</style>
